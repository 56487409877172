exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-admin-categories-index-tsx": () => import("./../../../src/pages/admin/categories/index.tsx" /* webpackChunkName: "component---src-pages-admin-categories-index-tsx" */),
  "component---src-pages-admin-categories-new-index-tsx": () => import("./../../../src/pages/admin/categories/new/index.tsx" /* webpackChunkName: "component---src-pages-admin-categories-new-index-tsx" */),
  "component---src-pages-admin-galleries-index-tsx": () => import("./../../../src/pages/admin/galleries/index.tsx" /* webpackChunkName: "component---src-pages-admin-galleries-index-tsx" */),
  "component---src-pages-admin-galleries-new-index-tsx": () => import("./../../../src/pages/admin/galleries/new/index.tsx" /* webpackChunkName: "component---src-pages-admin-galleries-new-index-tsx" */),
  "component---src-pages-admin-images-index-tsx": () => import("./../../../src/pages/admin/images/index.tsx" /* webpackChunkName: "component---src-pages-admin-images-index-tsx" */),
  "component---src-pages-admin-images-new-index-tsx": () => import("./../../../src/pages/admin/images/new/index.tsx" /* webpackChunkName: "component---src-pages-admin-images-new-index-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-products-index-tsx": () => import("./../../../src/pages/admin/products/index.tsx" /* webpackChunkName: "component---src-pages-admin-products-index-tsx" */),
  "component---src-pages-admin-products-new-index-tsx": () => import("./../../../src/pages/admin/products/new/index.tsx" /* webpackChunkName: "component---src-pages-admin-products-new-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-edit-category-tsx": () => import("./../../../src/templates/editCategory.tsx" /* webpackChunkName: "component---src-templates-edit-category-tsx" */),
  "component---src-templates-edit-gallery-tsx": () => import("./../../../src/templates/editGallery.tsx" /* webpackChunkName: "component---src-templates-edit-gallery-tsx" */),
  "component---src-templates-edit-image-tsx": () => import("./../../../src/templates/editImage.tsx" /* webpackChunkName: "component---src-templates-edit-image-tsx" */),
  "component---src-templates-edit-product-tsx": () => import("./../../../src/templates/editProduct.tsx" /* webpackChunkName: "component---src-templates-edit-product-tsx" */),
  "component---src-templates-gallery-tsx": () => import("./../../../src/templates/gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

